<template>
  <div class="flex-1 overflow-y-auto">
    
    <template v-if="groups.length > 0">

      <div
        class="mb-8" 
        v-for="(group, index) in groups"
        :key="index"
      >
        <div class="flex justify-between items-center">
          <div class="text-lg font-semibold">
            {{ group.locationNameTo }} ({{ group.locationIdTo }})
          </div>        
          <div class="flex items-center space-x-4">                  

            <PButton 
              color="primary"
              @click="selectedGroup = group" 
              :disabled="group.selected.length === 0"              
            >
              <span v-html="$tk('Returns.ProcessSelected')"></span>
            </PButton>

          </div>
        </div>

        <div class="mt-4 overflow-hidden overflow-x-auto border border-gray-300 rounded">
          <POrderGridTable          
            :cols="cols"
            :orders="group.orders"        
            :totalCount="group.orders.length"
            :loading="isLoading"
            :selectable="true"
            :expandable="false"
            :expandAll="true"
            :showFooter="false"
            @select="onSelect($event, group)"
            @click="onClick($event, group)"          
            v-model="group.selected"
          >
          </POrderGridTable>
          
        </div>

      </div>  

    </template>

    <template v-else>
      
      <PAlert 
        type="info" 
        v-html="$tk('Returns.NoOrdersToReturn')"
      ></PAlert>

    </template>

    <PReturnDialog
      v-if="selectedGroup" 
      :group="selectedGroup"
      @close="onReturnDialogClose" 
    />

  </div>
</template>

<script>
import api from "@/api/orders"
import { mapGetters } from "vuex"
import { format, sub, add } from "date-fns"
import { groupBy, map, findIndex } from "lodash"
import PReturnDialog from "../dialogs/PReturnDialog"
import POrderGridTable from "@/components/ordergrid/POrderGridTable"

export default {
  
  name: "Returns",

  components: {
    POrderGridTable,
    PReturnDialog
  },

  data () {    
    return {
      cols: [        
        { text: this.$tk("Common.Order.OrderNo"), value: "id", component: "p-display-text", width: 110, align: "center", classes: "font-medium" },
        { text: this.$tk("Common.Order.DateSent"), value: "dateSend", component: "p-display-date", width: 110, align: "center" },
        { 
          nested: [            
            { text: this.$tk("Common.Order.RTI"), component: "p-display-product", aggregate: "p-display-product-images", align: "left", width: 220 },
            { text: this.$tk("Common.Order.Quantity"), value: "quantity", component: "p-display-number", aggregate: "p-display-sum", width: 80, align: "right" }, 
          ] 
        },
        { text: this.$tk("Common.Order.OrderedBy"), value: "createdBy", component: "p-display-user" },
        { text: this.$tk("Common.Order.Transporter"), value: "transporterName", component: "p-display-text" },
        { text: this.$tk("Common.Order.TransportRef"), value: "shipmentRef", component: "p-display-text" },
        { text: this.$tk("Common.Order.CarNo"), value: "shipmentCarNumber", component: "p-display-text", align: "center" },
        { text: this.$tk("Common.Order.MyReference"), value: "receiversRef", component: "p-display-text" },
        { text: this.$tk("Common.Order.ReturnDays"), value: "returnDays", component: "p-display-number", align: "center" },
        { text: this.$tk("Common.Order.Status"), component: "p-display-status", align: "center" },
      ],      
      isLoading: false,
      selectedGroup: null,
      groups: []
    }
  },

  computed: {
    ...mapGetters(["location"])
  },

  methods: {

    async onReturnDialogClose () {
      this.selectedGroup = null
      await this.getOrders()
    },
    
    onClick (order, group) {
      this.toggle(order, group)
    },
    
    onSelect (order, group) {
      this.toggle(order, group)
    },
    
    toggle (order, group) {      
      var index = findIndex(group.selected, selected => selected === order.id)
      if (index >= 0) {
        group.selected.splice(index, 1)
      } else {
        group.selected.push(order.id)
      }      
    },

    async getOrders () {
      
      this.isLoading = true

      try {

        let params = {
          transactionType: "HE",
          locationIdFrom: this.location.id
        }

        let filters = {
          term:      { value: "" },
          sendLower: { value: format(sub(new Date(), { days: 21 }), "yyyy-MM-dd") },
          sendUpper: { value: format(add(new Date(), { days: 7 }), "yyyy-MM-dd") },
          statuses:  [{ id: 16, value: true }        
          ]        
        }

        const orders = await api.getOrders(
          "orders",
          params,
          "dateOrdered",
          false,
          filters,
          1000
        )
      
        const groups = groupBy(orders, "locationIdTo")

        this.groups = map(groups, group => {
          return {
            locationIdFrom: group[0].locationIdFrom,
            locationNameFrom: group[0].locationNameFrom,
            locationIdTo: group[0].locationIdTo,
            locationNameTo: group[0].locationNameTo,
            orders: group,
            selected: []
          }
        })

      } catch (error) {
        this.$store.dispatch("notify", {
          type: "negative",
          text: error.reason
        })
      }

      this.isLoading = false
    }

  },

  async created () {
    await this.getOrders ()
  }  
}
</script>